import BreadcrumbNav from 'components/BreadcrumbNav/BreadcrumbNav';
import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import WarningDialog from 'components/WarningDialog/WarningDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { ICampaignValues } from 'features/direct/campaign/components/CampaignTabsForm/CampaignFormValues';
import CampaignTabsForm, {
  campaignButtons,
} from 'features/direct/campaign/components/CampaignTabsForm/CampaignTabsForm';
import { RefetchCampaignQuery } from 'features/direct/campaign/types/campaign';
import CampaignFormValidation from 'features/direct/campaign/validations/campaigns';
import { formatTargetingDefinitionV2 } from 'features/targetingV2/utils/formValuesFormatting';
import { Formik } from 'formik';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import useWarning from 'hooks/Warning/useWarning';
import { IFieldUpdate, IFieldValidate } from 'interfaces';
import {
  BillingType,
  Campaign,
  EntityType,
  FrequencyCapTimeUnit,
  Order,
  Pacing,
  Tag,
  TargetingVersion,
  User,
  useUpdateCampaignMutation,
} from 'interfaces/generated.types';
import numbro from 'numbro';
import React, { useState } from 'react';
import { createDirectBreadcrumbConfig } from 'utils/breadcrumbConfigFactory';
import {
  calculateTotalCampaignsObjectiveWithoutCurrentCampaign,
  createSelectOption,
  createSelectOptions,
  createUserOption,
  transformIabCategoryOptions,
} from 'utils/dataTransformation';
import dateUtils, { TimeZones } from 'utils/date';
import { getDefaultTargetingGroupWithCountryParamsBasedOnTerritory } from 'utils/defaultsByTerritory';
import { handleFieldUpdate, handleFieldValidate } from 'utils/forms';
import { numbroDecimalFormatting } from 'utils/numbers';
import {
  groupTargetingGroups,
  ITargetingDefinition,
} from 'utils/targetingDefinitions';

import {
  handleOnComplete,
  submitUpdateCampaign,
} from './UpdateCampaignContainerUtils/UpdateCampaignContainerUtils';

interface IUpdateCampaignMatch {
  params: {
    campaignId: string;
  };
}

export interface IUpdateCampaignContainerProps {
  history: History;
  campaign: Campaign;
  allTags: Tag[];
  allUsers: User[];
  currentUser: User;
  hasEditPermissions: boolean;
  match: IUpdateCampaignMatch;
  refetch: RefetchCampaignQuery;
}

const UpdateCampaignContainer = ({
  history,
  campaign,
  allTags,
  allUsers,
  currentUser,
  hasEditPermissions,
  match,
  refetch,
}: IUpdateCampaignContainerProps) => {
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Campaign.",
    'Please try again later.',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');

  const {
    hasWarning,
    handleWarningContinue,
    toggleWarningModal,
    setWarningModal,
  } = useWarning();

  const [updateCampaign] = useUpdateCampaignMutation({
    onCompleted(data) {
      handleOnComplete({
        response: data,
        history,
        selectedJourney,
      });
    },
  });
  const validateCampaign = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const totalCampaignsObjective =
    calculateTotalCampaignsObjectiveWithoutCurrentCampaign({
      allCampaigns: (campaign.order as Order).campaigns,
      currentCampaignId: campaign.id,
    });

  const defaultGeneralGroup = activeTerritory
    ? getDefaultTargetingGroupWithCountryParamsBasedOnTerritory(activeTerritory)
    : null;

  const targeting =
    campaign.targetingDefinition && campaign.targetingDefinition
      ? groupTargetingGroups(
          campaign.targetingDefinition as ITargetingDefinition
        )
      : { groups: [], templates: [], general: defaultGeneralGroup };

  const initialValues: ICampaignValues = {
    priority: campaign.priority,
    offsetCpm:
      typeof campaign.offsetCpm === 'number'
        ? numbro(campaign.offsetCpm).format(numbroDecimalFormatting)
        : '',
    name: campaign.name as string,
    id: campaign.id,
    altId: campaign.altId,
    pacing: campaign.pacing as Pacing,
    objective: campaign.objective
      ? numbro(campaign.objective).format(numbroDecimalFormatting)
      : '',
    unlimitedObjective: !campaign.objective,
    dailyCap: campaign.dailyCap
      ? numbro(campaign.dailyCap).format(numbroDecimalFormatting)
      : '',
    startDate: campaign.startDate
      ? dateUtils.getDateInSpecificTimezone(
          campaign.startDate,
          campaign.timeZone
        )
      : null,
    endDate: campaign.endDate
      ? dateUtils.getDateInSpecificTimezone(campaign.endDate, campaign.timeZone)
      : null,
    noEndDate: !campaign.endDate,
    billingType: campaign.billingType as BillingType,
    timeZone: campaign.timeZone as TimeZones,
    orderName: createSelectOption(campaign.order as Order),
    frequencyCapCount: campaign.frequencyCapCount || '',
    frequencyCapTimeUnit: campaign.frequencyCapTimeUnit as FrequencyCapTimeUnit,
    frequencyCapValue: campaign.frequencyCapValue || '',
    currency: campaign.currency,
    cpm:
      typeof campaign.cpm === 'number'
        ? numbro(campaign.cpm).format(numbroDecimalFormatting)
        : '',
    iabCategoryCodes: transformIabCategoryOptions(campaign.iabCategories),
    tags: createSelectOptions(campaign.tags),
    journey: campaignButtons[0].url,
    owner: createUserOption(campaign.owner as User),
    status: campaign.status,
    targetingMasterTemplates: targeting.templates,
    targetingGroups: targeting.groups,
    targetingGeneralGroup: targeting.general,
    territory: campaign.territory,
    sponsorshipRevenue: campaign.sponsorshipRevenue,
    targetingVersion: campaign.targetingVersion,
    externalId: campaign.externalId,
    externalSystem: campaign.externalSystem,
    targetingRestrictions: campaign.targetingRestrictions || [],
    targetingDefinitionV2: campaign.targetingDefinitionV2
      ? formatTargetingDefinitionV2(campaign.targetingDefinitionV2)
      : null,
  };

  const location = usePreviousLocation();
  const breadcrumbConfig = createDirectBreadcrumbConfig(campaign);
  const shouldHaveExternalId = !!campaign.externalId;
  const shouldValidateTargetingV2 =
    campaign.targetingVersion === TargetingVersion.TargetingV2 &&
    !!campaign.targetingDefinitionV2;

  return (
    <>
      <BreadcrumbNav config={breadcrumbConfig} data-testid="breadcrumbNav" />
      <Formik<ICampaignValues>
        initialValues={initialValues}
        validationSchema={CampaignFormValidation(
          (campaign.order as Order).startDate,
          (campaign.order as Order).endDate,
          totalCampaignsObjective,
          activeTerritory,
          (campaign.order as Order).objective,
          shouldHaveExternalId,
          shouldValidateTargetingV2
        )}
        onSubmit={submitUpdateCampaign(
          updateCampaign,
          initialValues,
          toggleErrorModal,
          setSelectedJourney
        )}
      >
        {(formikProps) => (
          <CampaignTabsForm
            {...formikProps}
            refetch={refetch}
            allTags={allTags}
            allUsers={allUsers}
            currentUser={currentUser}
            update
            totalCampaignsObj={totalCampaignsObjective}
            orderObj={(campaign.order as Order).objective}
            goBackTo={{
              pathname: location.state?.from || '/campaigns',
              state: location.state?.parent || {},
            }}
            hasEditPermissions={hasEditPermissions}
            allAds={campaign.ads}
            match={match}
            history={history}
            campaign={campaign}
            onFieldValidate={({
              entity,
              handleContinue,
              handleCancel,
            }: IFieldValidate) =>
              handleFieldValidate({
                validate: validateCampaign,
                entity: {
                  ...entity,
                  id: formikProps.values.id,
                  type: EntityType.Campaign,
                },
                toggleErrorModal,
                setWarningModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
                handleContinue,
                handleCancel,
              })
            }
            onFieldUpdate={({ entity }: IFieldUpdate) =>
              handleFieldUpdate({
                entity: { ...entity, id: formikProps.values.id },
                update: updateCampaign,
                toggleErrorModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
              })
            }
            shouldHaveExternalId={shouldHaveExternalId}
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updateCampaign"
        errorMessages={errorMessages}
      />
      <WarningDialog
        handleContinue={handleWarningContinue}
        handleClose={toggleWarningModal}
        isOpen={hasWarning}
        dataTc="updateCampaignWarningDialog"
      />
    </>
  );
};

export default UpdateCampaignContainer;
