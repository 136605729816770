import gql from 'graphql-tag';

export const GET_CAMPAIGN = gql`
  query Campaign($id: ID!, $territories: [Territory!]!) {
    campaign(id: $id) {
      id
      altId
      name
      pacing
      startDate
      endDate
      billingType
      timeZone
      currency
      cpm
      frequencyCapCount
      frequencyCapValue
      frequencyCapTimeUnit
      objective
      impressions
      dailyCap
      offsetCpm
      priority
      pacing
      status
      territory
      sponsorshipRevenue
      targetingVersion
      externalId
      externalSystem
      sanities {
        id
        type
        name
      }
      tags {
        id
        name
      }
      order {
        id
        name
        advertiser {
          id
          name
          agency {
            id
            name
          }
        }
        campaigns {
          id
          name
          objective
        }
        startDate
        endDate
        objective
        owner {
          id
          email
          status
        }
        status
        externalSystemId
      }
      iabCategories {
        code
      }
      owner {
        id
        email
        status
      }
      targetingRestrictions
      targetingDefinition {
        id
        masterTargetingTemplates {
          id
          name
          description
          isMasterTemplate
          targetingGroups {
            id
            masterTargetingGroupId
            listenerAccuracy
            channels {
              id
            }
            audienceParams {
              ... on KeyValueGroupParam {
                keyValues {
                  key
                  value
                }
                clusivity
              }
              ... on AgeParam {
                ageRange {
                  minAge
                  maxAge
                }
                clusivity
              }
              ... on GenderParam {
                genders
                clusivity
              }
              ... on DayAndTimeParam {
                timeZone
                daysOfWeek {
                  day
                  hours
                }
                clusivity
              }
              ... on DeviceTypeParam {
                deviceTypes
                clusivity
              }
              ... on DeviceOperatingSystemParam {
                deviceOperatingSystems {
                  versions
                  name
                }
                clusivity
              }
              ... on ConnectionTypeParam {
                clusivity
                connectionTypes
              }
              ... on DeviceMakerParam {
                deviceMakers {
                  name
                  models
                }
                clusivity
              }
              ... on BrowserLanguageParam {
                browserLanguages {
                  description
                  code
                }
                clusivity
              }
              ... on PostalCodeParam {
                postalCodes
                postcodeGroups {
                  id
                  name
                }
                clusivity
              }
              ... on LocationParam {
                positions {
                  latitude
                  longitude
                  radius
                }
                clusivity
              }
              ... on DmpSegmentParam {
                segmentType
                segments {
                  ... on NielsenSegment {
                    id
                    fullName
                  }
                }
                clusivity
              }
              ... on DmaParam {
                dmas {
                  code
                  name
                }
                clusivity
              }

              ... on AudienceSegmentParam {
                segments {
                  id
                  displayName
                }
                clusivity
              }
              ... on GenreParam {
                genres
                clusivity
              }
              ... on ItunesCategoryParam {
                categories
                clusivity
              }
              ... on PodcastParam {
                podcastTargets {
                  collectionId
                  episodeId
                  showId
                  name
                }
                clusivity
              }
              ... on AdPositionParam {
                positions {
                  position
                  midRollPositions
                }
                clusivity
              }
              ... on IspParam {
                isps {
                  id
                  name
                }
                clusivity
              }
              ... on CountryParam {
                code
                name
                clusivity
                countryGroups {
                  name
                  id
                }
                subdivisions {
                  ... on CityParam {
                    name
                    regionCode
                    subRegionCode
                  }
                  ... on RegionParam {
                    name
                    code
                    subRegions {
                      name
                      code
                    }
                  }
                }
              }
            }
          }
        }
        generalTargetingGroup {
          id
          listenerAccuracy
          channels {
            id
          }
          audienceParams {
            ... on KeyValueGroupParam {
              keyValues {
                key
                value
              }
              clusivity
            }
            ... on AgeParam {
              ageRange {
                minAge
                maxAge
              }
              clusivity
            }
            ... on GenderParam {
              genders
              clusivity
            }
            ... on DayAndTimeParam {
              timeZone
              daysOfWeek {
                day
                hours
              }
              clusivity
            }
            ... on DeviceTypeParam {
              deviceTypes
              clusivity
            }
            ... on DeviceOperatingSystemParam {
              deviceOperatingSystems {
                versions
                name
              }
              clusivity
            }
            ... on ConnectionTypeParam {
              clusivity
              connectionTypes
            }
            ... on DeviceMakerParam {
              deviceMakers {
                name
                models
              }
              clusivity
            }
            ... on BrowserLanguageParam {
              browserLanguages {
                description
                code
              }
              clusivity
            }
            ... on PostalCodeParam {
              postalCodes
              postcodeGroups {
                id
                name
              }
              clusivity
            }
            ... on LocationParam {
              positions {
                latitude
                longitude
                radius
              }
              clusivity
            }
            ... on DmpSegmentParam {
              segmentType
              segments {
                ... on NielsenSegment {
                  id
                  fullName
                }
              }
              clusivity
            }
            ... on DmaParam {
              dmas {
                code
                name
              }
              clusivity
            }

            ... on AudienceSegmentParam {
              segments {
                id
                displayName
              }
              clusivity
            }
            ... on GenreParam {
              genres
              clusivity
            }
            ... on ItunesCategoryParam {
              categories
              clusivity
            }
            ... on PodcastParam {
              podcastTargets {
                collectionId
                episodeId
                showId
                name
              }
              clusivity
            }
            ... on AdPositionParam {
              positions {
                position
                midRollPositions
              }
              clusivity
            }
            ... on IspParam {
              isps {
                id
                name
              }
              clusivity
            }
            ... on CountryParam {
              code
              name
              clusivity
              countryGroups {
                name
                id
              }
              subdivisions {
                ... on CityParam {
                  name
                  regionCode
                  subRegionCode
                }
                ... on RegionParam {
                  name
                  code
                  subRegions {
                    name
                    code
                  }
                }
              }
            }
          }
        }
        targetingGroups {
          id
          masterTargetingGroupId
          listenerAccuracy
          channels {
            id
          }
          audienceParams {
            ... on KeyValueGroupParam {
              keyValues {
                key
                value
              }
              clusivity
            }
            ... on AgeParam {
              ageRange {
                minAge
                maxAge
              }
              clusivity
            }
            ... on GenderParam {
              genders
              clusivity
            }
            ... on DayAndTimeParam {
              timeZone
              daysOfWeek {
                day
                hours
              }
              clusivity
            }
            ... on DeviceTypeParam {
              deviceTypes
              clusivity
            }
            ... on DeviceOperatingSystemParam {
              deviceOperatingSystems {
                versions
                name
              }
              clusivity
            }
            ... on ConnectionTypeParam {
              clusivity
              connectionTypes
            }
            ... on DeviceMakerParam {
              deviceMakers {
                name
                models
              }
              clusivity
            }
            ... on BrowserLanguageParam {
              browserLanguages {
                description
                code
              }
              clusivity
            }
            ... on PostalCodeParam {
              postalCodes
              postcodeGroups {
                id
                name
              }
              clusivity
            }
            ... on LocationParam {
              positions {
                latitude
                longitude
                radius
              }
              clusivity
            }
            ... on DmpSegmentParam {
              segmentType
              segments {
                ... on NielsenSegment {
                  id
                  fullName
                }
              }
              clusivity
            }
            ... on DmaParam {
              dmas {
                code
                name
              }
              clusivity
            }
            ... on AudienceSegmentParam {
              segments {
                id
                displayName
              }
              clusivity
            }
            ... on GenreParam {
              genres
              clusivity
            }
            ... on ItunesCategoryParam {
              categories
              clusivity
            }
            ... on PodcastParam {
              podcastTargets {
                collectionId
                episodeId
                showId
                name
              }
              clusivity
            }
            ... on AdPositionParam {
              positions {
                position
                midRollPositions
              }
              clusivity
            }
            ... on IspParam {
              isps {
                id
                name
              }
              clusivity
            }
            ... on CountryParam {
              code
              name
              clusivity
              countryGroups {
                name
                id
              }
              subdivisions {
                ... on CityParam {
                  name
                  regionCode
                  subRegionCode
                }
                ... on RegionParam {
                  name
                  code
                  subRegions {
                    name
                    code
                  }
                }
              }
            }
          }
        }
      }
      targetingDefinitionV2 {
        id
        audienceTargeting {
          audiences {
            parameters {
              type
              clusivity
              values {
                id
                name
                active
                activeGrades
              }
              enabledGrades
            }
          }
        }
        locationTargeting {
          locations {
            limitLocationParameterValue {
              id
            }
            accuracyRadius
            parameters {
              type
              clusivity
              values {
                id
                name
                parents {
                  id
                  name
                }
              }
            }
          }
        }
        technologyTargeting {
          technologies {
            parameters {
              type
              clusivity
              values {
                id
                name
              }
            }
          }
        }
        inventoryTargeting {
          inventories {
            parameters {
              type
              clusivity
              values {
                id
                name
                active
                parents {
                  id
                  name
                }
              }
            }
          }
        }
        dayTimeTargeting {
          clusivity
          timeZone
          daysOfWeek {
            day
            hours
          }
        }
        customTargeting {
          customGroups {
            parameters {
              clusivity
              customKvps {
                key
                value
              }
            }
          }
        }
      }
      ads(territories: $territories) {
        id
        altId
        name
        startDate {
          isInherited
          value
        }
        endDate {
          isInherited
          value
        }
        weighting
        impressions
        dailyCap
        status
        frequencyCapCount
        frequencyCapValue
        frequencyCapTimeUnit
        creativeAudio {
          id
        }
        creativeImages {
          id
        }
        creativeRedirect {
          id
        }
        sequenceStart
        sequence {
          id
          name
        }
        tags {
          id
          name
        }
      }
    }
  }
`;

export const GET_CAMPAIGN_TIME_SERIES = gql`
  query CampaignTimeSeries(
    $id: ID!
    $timeZone: ZoneId!
    $territories: [Territory!]!
  ) {
    campaign(id: $id) {
      id
      timeZone
      objective
      impressions
      companionImpressions
      pacing
      ltr
      sanities {
        id
        type
        name
      }
      timeSeries(params: { timeUnit: HOUR, timeZone: $timeZone }) {
        impressions {
          dataPoints {
            t
            v
          }
        }
        ltr {
          dataPoints {
            t
            v
          }
        }
      }
      clickThroughRate
      ads(territories: $territories) {
        creativeImages {
          id
        }
        creativeRedirect {
          id
        }
      }
    }
  }
`;

export const GET_INITIAL_DATA_FOR_CREATING_AD = gql`
  query initialDataForCreateAd($id: ID!, $territory: Territory!) {
    getLocationParameterValueByTerritory(territory: $territory) {
      id
    }
    campaign(id: $id) {
      id
      name
      startDate
      endDate
      timeZone
      cpm
      dailyCap
      objective
      territory
      targetingVersion
      order {
        id
        endDate
        timeZone
        advertiser {
          id
        }
      }
      tags {
        id
        name
      }
      targetingDefinitionV2 {
        id
      }
      targetingDefinition {
        id
        masterTargetingTemplates {
          id
          name
          description
          isMasterTemplate
          targetingGroups {
            id
            masterTargetingGroupId
            listenerAccuracy
            channels {
              id
            }
            audienceParams {
              ... on KeyValueGroupParam {
                keyValues {
                  key
                  value
                }
                clusivity
              }
              ... on AgeParam {
                ageRange {
                  minAge
                  maxAge
                }
                clusivity
              }
              ... on GenderParam {
                genders
                clusivity
              }
              ... on DayAndTimeParam {
                timeZone
                daysOfWeek {
                  day
                  hours
                }
                clusivity
              }
              ... on DeviceTypeParam {
                deviceTypes
                clusivity
              }
              ... on DeviceOperatingSystemParam {
                deviceOperatingSystems {
                  versions
                  name
                }
                clusivity
              }
              ... on ConnectionTypeParam {
                clusivity
                connectionTypes
              }
              ... on DeviceMakerParam {
                deviceMakers {
                  name
                  models
                }
                clusivity
              }
              ... on BrowserLanguageParam {
                browserLanguages {
                  description
                  code
                }
                clusivity
              }
              ... on PostalCodeParam {
                postalCodes
                postcodeGroups {
                  id
                  name
                }
                clusivity
              }
              ... on LocationParam {
                positions {
                  latitude
                  longitude
                  radius
                }
                clusivity
              }
              ... on DmpSegmentParam {
                segmentType
                segments {
                  ... on NielsenSegment {
                    id
                    fullName
                  }
                }
                clusivity
              }
              ... on DmaParam {
                dmas {
                  code
                  name
                }
                clusivity
              }
              ... on AudienceSegmentParam {
                segments {
                  id
                  displayName
                }
                clusivity
              }
              ... on GenreParam {
                genres
                clusivity
              }
              ... on ItunesCategoryParam {
                categories
                clusivity
              }
              ... on PodcastParam {
                podcastTargets {
                  collectionId
                  episodeId
                  showId
                  name
                }
                clusivity
              }
              ... on AdPositionParam {
                positions {
                  position
                  midRollPositions
                }
                clusivity
              }
              ... on IspParam {
                isps {
                  id
                  name
                }
                clusivity
              }
              ... on CountryParam {
                code
                name
                clusivity
                countryGroups {
                  name
                  id
                }
                subdivisions {
                  ... on CityParam {
                    name
                    regionCode
                    subRegionCode
                  }
                  ... on RegionParam {
                    name
                    code
                    subRegions {
                      name
                      code
                    }
                  }
                }
              }
            }
          }
        }
        generalTargetingGroup {
          id
          listenerAccuracy
          channels {
            id
          }
          audienceParams {
            ... on KeyValueGroupParam {
              keyValues {
                key
                value
              }
              clusivity
            }
            ... on AgeParam {
              ageRange {
                minAge
                maxAge
              }
              clusivity
            }
            ... on GenderParam {
              genders
              clusivity
            }
            ... on DayAndTimeParam {
              timeZone
              daysOfWeek {
                day
                hours
              }
              clusivity
            }
            ... on DeviceTypeParam {
              deviceTypes
              clusivity
            }
            ... on DeviceOperatingSystemParam {
              deviceOperatingSystems {
                versions
                name
              }
              clusivity
            }
            ... on ConnectionTypeParam {
              clusivity
              connectionTypes
            }
            ... on DeviceMakerParam {
              deviceMakers {
                name
                models
              }
              clusivity
            }
            ... on BrowserLanguageParam {
              browserLanguages {
                description
                code
              }
              clusivity
            }
            ... on PostalCodeParam {
              postalCodes
              postcodeGroups {
                id
                name
              }
              clusivity
            }
            ... on LocationParam {
              positions {
                latitude
                longitude
                radius
              }
              clusivity
            }
            ... on DmpSegmentParam {
              segmentType
              segments {
                ... on NielsenSegment {
                  id
                  fullName
                }
              }
              clusivity
            }
            ... on DmaParam {
              dmas {
                code
                name
              }
              clusivity
            }
            ... on AudienceSegmentParam {
              segments {
                id
                displayName
              }
              clusivity
            }
            ... on GenreParam {
              genres
              clusivity
            }
            ... on ItunesCategoryParam {
              categories
              clusivity
            }
            ... on PodcastParam {
              podcastTargets {
                collectionId
                episodeId
                showId
                name
              }
              clusivity
            }
            ... on AdPositionParam {
              positions {
                position
                midRollPositions
              }
              clusivity
            }
            ... on IspParam {
              isps {
                id
                name
              }
              clusivity
            }
            ... on CountryParam {
              code
              name
              clusivity
              countryGroups {
                name
                id
              }
              subdivisions {
                ... on CityParam {
                  name
                  regionCode
                  subRegionCode
                }
                ... on RegionParam {
                  name
                  code
                  subRegions {
                    name
                    code
                  }
                }
              }
            }
          }
        }
        targetingGroups {
          id
          masterTargetingGroupId
          listenerAccuracy
          channels {
            id
          }
          audienceParams {
            ... on KeyValueGroupParam {
              keyValues {
                key
                value
              }
              clusivity
            }
            ... on AgeParam {
              ageRange {
                minAge
                maxAge
              }
              clusivity
            }
            ... on GenderParam {
              genders
              clusivity
            }
            ... on DayAndTimeParam {
              timeZone
              daysOfWeek {
                day
                hours
              }
              clusivity
            }
            ... on DeviceTypeParam {
              deviceTypes
              clusivity
            }
            ... on DeviceOperatingSystemParam {
              deviceOperatingSystems {
                versions
                name
              }
              clusivity
            }
            ... on ConnectionTypeParam {
              clusivity
              connectionTypes
            }
            ... on DeviceMakerParam {
              deviceMakers {
                name
                models
              }
              clusivity
            }
            ... on BrowserLanguageParam {
              browserLanguages {
                description
                code
              }
              clusivity
            }
            ... on PostalCodeParam {
              postalCodes
              postcodeGroups {
                id
                name
              }
              clusivity
            }
            ... on LocationParam {
              positions {
                latitude
                longitude
                radius
              }
              clusivity
            }
            ... on DmpSegmentParam {
              segmentType
              segments {
                ... on NielsenSegment {
                  id
                  fullName
                }
              }
              clusivity
            }
            ... on DmaParam {
              dmas {
                code
                name
              }
              clusivity
            }
            ... on AudienceSegmentParam {
              segments {
                id
                displayName
              }
              clusivity
            }
            ... on GenreParam {
              genres
              clusivity
            }
            ... on ItunesCategoryParam {
              categories
              clusivity
            }
            ... on PodcastParam {
              podcastTargets {
                collectionId
                episodeId
                showId
                name
              }
              clusivity
            }
            ... on AdPositionParam {
              positions {
                position
                midRollPositions
              }
              clusivity
            }
            ... on IspParam {
              isps {
                id
                name
              }
              clusivity
            }
            ... on CountryParam {
              code
              name
              clusivity
              countryGroups {
                name
                id
              }
              subdivisions {
                ... on CityParam {
                  name
                  regionCode
                  subRegionCode
                }
                ... on RegionParam {
                  name
                  code
                  subRegions {
                    name
                    code
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const GET_ALL_CAMPAIGNS_FOR_GRAPH_DATA = gql`
  query AllCampaignsForGraphData(
    $filter: CampaignFilter
    $territories: [Territory!]!
  ) {
    allCampaigns(filter: $filter, territories: $territories) {
      id
      startDate
      endDate
      timeZone
      status
      sanities {
        id
        type
        name
      }
    }
  }
`;

export const GET_PAGINATED_CAMPAIGNS = gql`
  query offsetPaginateCampaigns(
    $pageNumber: Int
    $itemsPerPage: Int
    $orderable: CampaignOrdering
    $search: String
    $filter: CampaignFilter
    $territories: [Territory!]!
    $orderId: ID
  ) {
    offsetPaginateCampaigns(
      pageNumber: $pageNumber
      itemsPerPage: $itemsPerPage
      orderable: $orderable
      search: $search
      filter: $filter
      territories: $territories
      orderId: $orderId
    ) {
      campaigns {
        id
        altId
        name
        pacing
        priority
        startDate
        endDate
        status
        billingType
        timeZone
        currency
        cpm
        frequencyCapCount
        frequencyCapValue
        frequencyCapTimeUnit
        objective
        impressions
        companionImpressions
        estimatedCompletion
        ltr
        sanities {
          id
          type
          name
        }
        deliveryPace
        order {
          id
          name
          externalId
          advertiser {
            id
            name
          }
        }
        owner {
          id
          email
          status
        }
        ownerName
        clickThroughRate
        archived
      }
      pageMetaData {
        pageNumber
        totalPages
        totalItems
      }
    }
  }
`;

export const GET_CAMPAIGN_AD_TARGETTING = gql`
  query CampaignAds($id: ID!, $territories: [Territory!]!) {
    campaign(id: $id) {
      id
      altId
      name
      owner {
        id
        email
        status
      }
      cpm
      frequencyCapCount
      frequencyCapValue
      frequencyCapTimeUnit
      dailyCap
      order {
        name
        id
      }
      ads(territories: $territories) {
        id
        altId
        name
        status
        targetingDefinition {
          id
          masterTargetingTemplates {
            id
            name
            description
            isMasterTemplate
            targetingGroups {
              id
              masterTargetingGroupId
              listenerAccuracy
              channels {
                id
              }
              audienceParams {
                ... on KeyValueGroupParam {
                  keyValues {
                    key
                    value
                  }
                  clusivity
                }
                ... on AgeParam {
                  ageRange {
                    minAge
                    maxAge
                  }
                  clusivity
                }
                ... on GenderParam {
                  genders
                  clusivity
                }
                ... on DayAndTimeParam {
                  timeZone
                  daysOfWeek {
                    day
                    hours
                  }
                  clusivity
                }
                ... on DeviceTypeParam {
                  deviceTypes
                  clusivity
                }
                ... on DeviceOperatingSystemParam {
                  deviceOperatingSystems {
                    versions
                    name
                  }
                  clusivity
                }
                ... on ConnectionTypeParam {
                  clusivity
                  connectionTypes
                }
                ... on DeviceMakerParam {
                  deviceMakers {
                    name
                    models
                  }
                  clusivity
                }
                ... on BrowserLanguageParam {
                  browserLanguages {
                    description
                    code
                  }
                  clusivity
                }
                ... on PostalCodeParam {
                  postalCodes
                  postcodeGroups {
                    id
                    name
                  }
                  clusivity
                }
                ... on LocationParam {
                  positions {
                    latitude
                    longitude
                    radius
                  }
                  clusivity
                }
                ... on DmpSegmentParam {
                  segmentType
                  segments {
                    ... on NielsenSegment {
                      id
                      fullName
                    }
                  }
                  clusivity
                }
                ... on DmaParam {
                  dmas {
                    code
                    name
                  }
                  clusivity
                }
                ... on AudienceSegmentParam {
                  segments {
                    id
                    displayName
                  }
                  clusivity
                }
                ... on GenreParam {
                  genres
                  clusivity
                }
                ... on ItunesCategoryParam {
                  categories
                  clusivity
                }
                ... on PodcastParam {
                  podcastTargets {
                    collectionId
                    episodeId
                    showId
                    name
                  }
                  clusivity
                }
                ... on AdPositionParam {
                  positions {
                    position
                    midRollPositions
                  }
                  clusivity
                }
                ... on IspParam {
                  isps {
                    id
                    name
                  }
                  clusivity
                }
                ... on CountryParam {
                  code
                  name
                  clusivity
                  countryGroups {
                    name
                    id
                  }
                  subdivisions {
                    ... on CityParam {
                      name
                      regionCode
                      subRegionCode
                    }
                    ... on RegionParam {
                      name
                      code
                      subRegions {
                        name
                        code
                      }
                    }
                  }
                }
              }
            }
          }
          generalTargetingGroup {
            id
            listenerAccuracy
            channels {
              id
            }
            audienceParams {
              ... on KeyValueGroupParam {
                keyValues {
                  key
                  value
                }
                clusivity
              }
              ... on AgeParam {
                ageRange {
                  minAge
                  maxAge
                }
                clusivity
              }
              ... on GenderParam {
                genders
                clusivity
              }
              ... on DayAndTimeParam {
                timeZone
                daysOfWeek {
                  day
                  hours
                }
                clusivity
              }
              ... on DeviceTypeParam {
                deviceTypes
                clusivity
              }
              ... on DeviceOperatingSystemParam {
                deviceOperatingSystems {
                  versions
                  name
                }
                clusivity
              }
              ... on ConnectionTypeParam {
                clusivity
                connectionTypes
              }
              ... on DeviceMakerParam {
                deviceMakers {
                  name
                  models
                }
                clusivity
              }
              ... on BrowserLanguageParam {
                browserLanguages {
                  description
                  code
                }
                clusivity
              }
              ... on PostalCodeParam {
                postalCodes
                postcodeGroups {
                  id
                  name
                }
                clusivity
              }
              ... on LocationParam {
                positions {
                  latitude
                  longitude
                  radius
                }
                clusivity
              }
              ... on DmpSegmentParam {
                segmentType
                segments {
                  ... on NielsenSegment {
                    id
                    fullName
                  }
                }
                clusivity
              }
              ... on DmaParam {
                dmas {
                  code
                  name
                }
                clusivity
              }
              ... on AudienceSegmentParam {
                segments {
                  id
                  displayName
                }
                clusivity
              }
              ... on GenreParam {
                genres
                clusivity
              }
              ... on ItunesCategoryParam {
                categories
                clusivity
              }
              ... on PodcastParam {
                podcastTargets {
                  collectionId
                  episodeId
                  showId
                  name
                }
                clusivity
              }
              ... on AdPositionParam {
                positions {
                  position
                  midRollPositions
                }
                clusivity
              }
              ... on IspParam {
                isps {
                  id
                  name
                }
                clusivity
              }
              ... on CountryParam {
                code
                name
                clusivity
                countryGroups {
                  name
                  id
                }
                subdivisions {
                  ... on CityParam {
                    name
                    regionCode
                    subRegionCode
                  }
                  ... on RegionParam {
                    name
                    code
                    subRegions {
                      name
                      code
                    }
                  }
                }
              }
            }
          }
          targetingGroups {
            id
            masterTargetingGroupId
            listenerAccuracy
            channels {
              id
            }
            audienceParams {
              ... on KeyValueGroupParam {
                keyValues {
                  key
                  value
                }
                clusivity
              }
              ... on AgeParam {
                ageRange {
                  minAge
                  maxAge
                }
                clusivity
              }
              ... on GenderParam {
                genders
                clusivity
              }
              ... on DayAndTimeParam {
                timeZone
                daysOfWeek {
                  day
                  hours
                }
                clusivity
              }
              ... on DeviceTypeParam {
                deviceTypes
                clusivity
              }
              ... on DeviceOperatingSystemParam {
                deviceOperatingSystems {
                  versions
                  name
                }
                clusivity
              }
              ... on ConnectionTypeParam {
                clusivity
                connectionTypes
              }
              ... on DeviceMakerParam {
                deviceMakers {
                  name
                  models
                }
                clusivity
              }
              ... on BrowserLanguageParam {
                browserLanguages {
                  description
                  code
                }
                clusivity
              }
              ... on PostalCodeParam {
                postalCodes
                postcodeGroups {
                  id
                  name
                }
                clusivity
              }
              ... on LocationParam {
                positions {
                  latitude
                  longitude
                  radius
                }
                clusivity
              }
              ... on DmpSegmentParam {
                segmentType
                segments {
                  ... on NielsenSegment {
                    id
                    fullName
                  }
                }
                clusivity
              }
              ... on DmaParam {
                dmas {
                  code
                  name
                }
                clusivity
              }
              ... on AudienceSegmentParam {
                segments {
                  id
                  displayName
                }
                clusivity
              }
              ... on GenreParam {
                genres
                clusivity
              }
              ... on ItunesCategoryParam {
                categories
                clusivity
              }
              ... on PodcastParam {
                podcastTargets {
                  collectionId
                  episodeId
                  showId
                  name
                }
                clusivity
              }
              ... on AdPositionParam {
                positions {
                  position
                  midRollPositions
                }
                clusivity
              }
              ... on IspParam {
                isps {
                  id
                  name
                }
                clusivity
              }
              ... on CountryParam {
                code
                name
                clusivity
                countryGroups {
                  name
                  id
                }
                subdivisions {
                  ... on CityParam {
                    name
                    regionCode
                    subRegionCode
                  }
                  ... on RegionParam {
                    name
                    code
                    subRegions {
                      name
                      code
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
