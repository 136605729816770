import Container from 'components/Container/Container';
import Loader from 'components/Loader/Loader';
import Redirecting from 'components/Redirecting/Redirecting';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import CreateAdContainer from 'features/direct/ad/containers/CreateAdContainer/CreateAdContainer';
import { History } from 'history';
import {
  Campaign,
  LocationParameterValue,
  Territory,
  useInitialDataForCreateAdQuery,
} from 'interfaces/generated.types';
import React from 'react';

interface ICreateAdPage {
  history: History;
  match: {
    params: {
      orderId: string;
      campaignId: string;
    };
  };
}

const CreateAdPage = ({ history, match }: ICreateAdPage) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { loading, data, error } = useInitialDataForCreateAdQuery({
    variables: {
      id: match.params.campaignId,
      territory: activeTerritory as Territory,
    },
  });

  const campaignData = data?.campaign;
  const locationParameterValue = data?.getLocationParameterValueByTerritory;

  if (!loading && (error || !campaignData))
    return <Redirecting history={history} />;

  return (
    <main>
      <Container>
        <h1 className="sr-only">Create Ad</h1>
        {loading ? (
          <Loader />
        ) : (
          <CreateAdContainer
            match={match}
            history={history}
            campaign={campaignData as Campaign}
            locationParameterValue={
              locationParameterValue as LocationParameterValue
            }
          />
        )}
      </Container>
    </main>
  );
};

export default CreateAdPage;
