import { format, isValid } from 'date-fns';
import targetingV2 from 'features/targetingV2/validations/targetingV2';
import { Pacing, Territory } from 'interfaces/generated.types';
import numbro from 'numbro';
import dateUtils, { TimeZones } from 'utils/date';
import { getDateFormatBasedOnTerritory } from 'utils/defaultsByTerritory';
import {
  numbroDecimalFormatting,
  parseFormattedValue,
  sumTwoNumbers,
} from 'utils/numbers';
import commons from 'validations/commons';
import * as Yup from 'yup';

const CampaignFormValidation = (
  orderStartDate: string | null,
  orderEndDate: string | null,
  totalCampaignsObjective: number | null,
  territory: Territory | undefined,
  orderObjective: number | null = 0,
  shouldHaveExternalId: boolean = false,
  shouldValidateTargetingV2: boolean = false
) =>
  Yup.object().shape(
    {
      orderName: Yup.object()
        .shape({
          label: Yup.string().required('Order Name label must be present'),
          value: Yup.string().required('Order Name value must be present'),
        })
        .required('Order Name is a required field')
        .nullable(),
      name: commons.name('Campaign Name'),
      owner: Yup.object()
        .shape({
          label: Yup.string().required('Owner label must be present'),
          value: Yup.string().required('Owner value must be present'),
        })
        .required('Owner is a required field')
        .nullable(),
      currency: commons
        .currency('currency')
        .when('cpm', {
          is: (value: number) => !!value,
          then: Yup.string().required(
            'Currency is required when a CPM is added'
          ),
        })
        .when('offsetCpm', {
          is: (value: number) => !!value,
          then: Yup.string().required(
            'Currency is required when an offset is added'
          ),
        }),
      objective: Yup.string()
        .test(
          'objective',
          'Campaign Objective should be higher than or equal to 1',
          (value: string) => {
            if (!value) {
              return true;
            }
            const numberValue = parseFormattedValue(value);
            return numberValue >= 1;
          }
        )
        .test(
          'objective',
          'Campaign Objective should be lower than or equal to 1,000,000,000',
          (value: string) => {
            if (!value) {
              return true;
            }
            const numberValue = parseFormattedValue(value);
            return numberValue <= 1000000000;
          }
        )
        .test(
          'objective',
          `This objective exceeds the total impressions for the related order. The total number of impressions available is ${numbro(
            orderObjective || 0
          ).format(numbroDecimalFormatting)}`,
          (value: string) => {
            if (
              !orderObjective ||
              !value ||
              typeof totalCampaignsObjective !== 'number'
            ) {
              return true;
            }
            const campaignsTotal = sumTwoNumbers(
              totalCampaignsObjective,
              value
            );
            const totalValue = parseFormattedValue(campaignsTotal);
            return totalValue <= orderObjective;
          }
        )
        .when(['unlimitedObjective'], {
          is: (unlimitedObjective) => !unlimitedObjective,
          then: Yup.string().required('Campaign Objective is a required field'),
        }),
      unlimitedObjective: Yup.boolean().when(['pacing'], {
        is: (pacing) => pacing !== Pacing.Asap,
        then: Yup.boolean().oneOf(
          [false],
          'A Campaign must have an objective impression if pacing is set to evenly or front-weighted'
        ),
      }),
      pacing: Yup.string().when(
        ['unlimitedObjective'],
        (unlimitedObjective: boolean, schema: any) =>
          schema.test(
            'pacing asap',
            'Campaign pacing cannot be set to evenly or front-weighted if there is no objective impression',
            (pacing: Pacing) => {
              if (unlimitedObjective) return pacing === Pacing.Asap;
              return true;
            }
          )
      ),
      dailyCap: Yup.string()
        .test(
          'dailyCap',
          'Campaign Daily Impression Cap should be higher than or equal to 1',
          (value: string) => {
            if (!value) {
              return true;
            }
            const numberValue = parseFormattedValue(value);
            return numberValue >= 1;
          }
        )
        .test(
          'dailyCap',
          'Campaign Daily Impression Cap should be lower than or equal to 1,000,000,000',
          (value: string) => {
            if (!value) {
              return true;
            }
            const numberValue = parseFormattedValue(value);
            return numberValue <= 1000000000;
          }
        ),
      cpm: commons.optionalPrice({
        messages: {
          minMessage: 'Campaign CPM should be higher than or equal to 0',
          maxMessage:
            'Campaign CPM should be lower than or equal to 999,999.99',
        },
        minimum: 0,
        maximum: 999999.99,
        fieldKey: 'cpm',
      }),
      timeZone: Yup.string().when(['startDate', 'endDate'], {
        is: (startDate: Date, endDate: Date) => !!startDate || !!endDate,
        then: Yup.string().required(
          'Timezone is required when a start or end date is added'
        ),
      }),
      startDate: Yup.date()
        .nullable()
        .default(null)
        .typeError('Campaign Start Date should be a valid date')
        .when('endDate', {
          is: (endDate: Date) => !!endDate,
          then: Yup.date().required(
            'Start date is required when an end date is added'
          ),
        })
        .when(['timeZone'], (timeZone: TimeZones, schema: Yup.DateSchema) => {
          if (!orderStartDate) {
            return schema;
          }
          return schema.test(
            'startDate',
            `Campaign Start Date should be after Order Start Date (${format(
              dateUtils.getDateInSpecificTimezone(orderStartDate, timeZone),
              getDateFormatBasedOnTerritory(territory, true),
              {
                useAdditionalWeekYearTokens: true,
                useAdditionalDayOfYearTokens: true,
              }
            )})`,
            (startDate: Date) => {
              if (!isValid(startDate)) {
                return true;
              }
              return dateUtils.isDateEqualOrAfterTheOther({
                date: startDate,
                dateToCompare: dateUtils.getDateInSpecificTimezone(
                  orderStartDate,
                  timeZone
                ),
                timeZone,
              });
            }
          );
        }),
      endDate: Yup.date()
        .nullable()
        .default(null)
        .typeError('Campaign End Date should be a valid date')
        .when(
          ['startDate', 'timeZone'],
          (
            startDate: Date | null,
            timeZone: TimeZones,
            schema: Yup.DateSchema
          ) => {
            if (!isValid(startDate)) {
              return schema;
            }
            return schema.test(
              'endDate',
              `Campaign End Date should be after ${format(
                startDate as Date,
                getDateFormatBasedOnTerritory(territory, true),
                {
                  useAdditionalWeekYearTokens: true,
                  useAdditionalDayOfYearTokens: true,
                }
              )}`,
              (endDate: Date) => {
                if (!isValid(endDate)) {
                  return true;
                }
                return dateUtils.isDateAfterTheOther({
                  date: endDate,
                  dateToCompare: startDate as Date,
                  timeZone,
                });
              }
            );
          }
        )
        .when(['timeZone'], (timeZone: TimeZones, schema: Yup.DateSchema) => {
          if (!orderEndDate) {
            return schema;
          }

          return schema.test(
            'endDate',
            `Campaign End Date should be before Order End Date (${format(
              dateUtils.getDateInSpecificTimezone(orderEndDate, timeZone),
              getDateFormatBasedOnTerritory(territory, true),
              {
                useAdditionalWeekYearTokens: true,
                useAdditionalDayOfYearTokens: true,
              }
            )})`,
            (endDate: Date) => {
              if (!isValid(endDate)) {
                return true;
              }
              return dateUtils.isDateEqualOrBeforeTheOther({
                date: endDate,
                dateToCompare: dateUtils.getDateInSpecificTimezone(
                  orderEndDate,
                  timeZone
                ),
                timeZone,
              });
            }
          );
        })
        .test(
          'endDate',
          'Campaign End Date should not be in the past',
          function endDateInThePast(endDate: Date) {
            if (!endDate || !isValid(endDate)) {
              return true;
            }
            const { timeZone } = this.parent;
            return dateUtils.isDateInTheFuture(endDate, timeZone);
          }
        ),
      ...commons.getFrequencyCapFields('Campaign Frequency Cap'),
      offsetCpm: commons.optionalPrice({
        messages: {
          minMessage: 'Campaign Off-set should be higher than or equal to 0',
          maxMessage:
            'Campaign Off-set should be lower than or equal to 999,999.99',
        },
        minimum: 0,
        maximum: 999999.99,
        fieldKey: 'offsetCpm',
      }),
      sponsorshipRevenue: commons.optionalPrice({
        messages: {
          minMessage:
            'Campaign Sponsorship Revenue should be higher than or equal to 0',
          maxMessage:
            'Campaign Sponsorship Revenue should be lower than or equal to 999,999.99',
        },
        minimum: 0,
        maximum: 999999.99,
        fieldKey: 'sponsorshipRevenue',
      }),
      priority: commons.optionalFieldWithValidation({
        message: 'Please select a valid priority',
        fieldKey: 'priority',
        valuesToCompareAgainst: ['1', '2', '3', '4', '5', '6', '7', '8', '9'],
      }),
      targetingMasterTemplates: Yup.array().of(
        Yup.object().shape({
          groups: Yup.array().of(
            Yup.object().shape(
              {
                audienceParams: commons.audienceParams('Listener parameters'),
                channels: Yup.array()
                  .of(Yup.string())
                  .when(['audienceParams'], {
                    is: (audienceParams: any) =>
                      audienceParams && audienceParams.length > 0,
                    then: Yup.array().required(
                      "It looks like your targeting group does not have channels assigned. Use the 'Channels' button to assign a channel."
                    ),
                  })
                  .nullable(),
              },
              ['audienceParams', 'channels'] as any
            )
          ),
        })
      ),
      targetingGeneralGroup: Yup.object()
        .shape(
          {
            audienceParams: commons.audienceParams('Listener parameters'),
          },
          ['audienceParams'] as any
        )
        .nullable(),
      targetingGroups: Yup.array().of(
        Yup.object().shape(
          {
            audienceParams: commons.audienceParams('Listener parameters'),
            channels: Yup.array()
              .of(Yup.string())
              .when(['audienceParams'], {
                is: (audienceParams: any) =>
                  audienceParams && audienceParams.length > 0,
                then: Yup.array().required(
                  "It looks like your targeting group does not have channels assigned. Use the 'Channels' button to assign a channel."
                ),
              })
              .nullable(),
          },
          ['audienceParams', 'channels'] as any
        )
      ),
      ...(shouldHaveExternalId
        ? {
            externalId: commons
              .max255Characters()
              .trim()
              .required('External ID is a required field'),
          }
        : {}),
      ...(shouldValidateTargetingV2
        ? {
            targetingDefinitionV2: Yup.object().shape({
              customTargeting: targetingV2.customTargeting,
            }),
          }
        : {}),
    },
    [
      ['frequencyCapCount', 'frequencyCapValue'],
      ['frequencyCapCount', 'frequencyCapTimeUnit'],
      ['frequencyCapValue', 'frequencyCapTimeUnit'],
      ['currency', 'cpm'],
      ['currency', 'offsetCpm'],
      ['startDate', 'endDate'],
      ['timeZone', 'startDate'],
      ['timeZone', 'endDate'],
      ['endDate', 'objective'],
      ['objective', 'unlimitedObjective'],
      ['pacing', 'unlimitedObjective'],
    ]
  );

export default CampaignFormValidation;
