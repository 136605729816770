import TabStyles from 'assets/styles/components/Tab.styles';
import classNames from 'classnames';
import EntityHeader from 'components/EntityHeader/EntityHeader';
import EntityProgressBar from 'components/EntityProgressBar/EntityProgressBar';
import FormButtons, {
  FormButtonVariants,
} from 'components/FormButtons/FormButtons';
import LeavingPrompt from 'components/LeavingPrompt/LeavingPrompt';
import Tab from 'components/Tab/Tab';
import ActivityTable from 'features/audit/components/ActivityTable/ActivityTable';
import AdsTable from 'features/direct/ad/components/AdsTable/AdsTable';
import CampaignDetails from 'features/direct/campaign/components/CampaignDetails/CampaignDetails';
import CampaignMonitoring from 'features/direct/campaign/components/CampaignMonitoring/CampaignMonitoring';
import { RefetchCampaignQuery } from 'features/direct/campaign/types/campaign';
import IabCategoriesTree from 'features/iabCategories/containers/IabCategoriesTree/IabCategoriesTree';
import TargetingVersionSelection from 'features/targetingV2/components/TargetingVersionSelection/TargetingVersionSelection';
import { Form } from 'formik';
import { History } from 'history';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import {
  IFieldUpdate,
  IFieldValidate,
  IFormProps,
  ILocation,
} from 'interfaces';
import {
  Ad,
  Campaign,
  CampaignStatus,
  EntityType,
  Order,
  Tag,
  TargetingVersion,
  User,
} from 'interfaces/generated.types';
import React, { useState } from 'react';
import { createNumberOfFormErrorsFn } from 'utils/forms';
import { handleOnSubmitJourney, handleTabHistory } from 'utils/journeys';

import AppBar from '@material-ui/core/AppBar/AppBar';
import Tabs from '@material-ui/core/Tabs/Tabs';

import {
  campaignDetailsFields,
  campaignStatusValues,
  getCampaignStatusData,
  ICampaignValues,
} from './CampaignFormValues';
import useStyles from './CampaignTabsForm.styles';

interface IUpdateCampaignMatch {
  params: {
    campaignId: string;
  };
}

export interface ICampaignProps {
  orderIdExists?: boolean;
  orderObj?: number | null;
  totalCampaignsObj: number | null;
  allOrders?: Order[] | [];
  update?: boolean;
  validateOrderFields?: (
    name: string,
    value: Date | number | null | string
  ) => void;
  goBackTo: ILocation;
  allTags: Tag[];
  allUsers: User[];
  currentUser: User;
  hasEditPermissions?: boolean;
  allAds?: Ad[];
  match?: IUpdateCampaignMatch;
  history?: History;
  campaign?: Campaign;
  onFieldUpdate?: (values: IFieldUpdate) => void;
  onFieldValidate?: (values: IFieldValidate) => void;
  refetch?: RefetchCampaignQuery;
  shouldHaveExternalId?: boolean;
}

export const campaignButtons = [
  {
    text: 'Save and Review',
    url: '/campaign/ID',
    update: true,
    dataTc: 'campaignReviewButton',
  },
  {
    text: 'Save and Create Ad',
    url: '/campaign/ID/ad',
    update: false,
    dataTc: 'campaignCreateAdButton',
  },
  {
    text: 'Save and View All Campaigns',
    url: '/campaigns',
    update: false,
    dataTc: 'campaignListButton',
  },
];

export const getDefaultTab = (campaignStatus?: string, location?: any) => {
  if (location && location.state) {
    const { entityList, tab } = location.state;
    if (entityList === 'listAds') return 4;
    if (tab) return tab;
  }
  return campaignStatus &&
    (campaignStatus === CampaignStatus.Live ||
      campaignStatus === CampaignStatus.Completed)
    ? 0
    : 1;
};

const getCampaignDetailsTabErrors = createNumberOfFormErrorsFn();

const CampaignTabsForm = (
  props: IFormProps<ICampaignValues> & ICampaignProps
) => {
  const {
    handleSubmit,
    isSubmitting,
    isValid,
    dirty,
    goBackTo,
    setFieldValue,
    update = false,
    values,
    hasEditPermissions = true,
    allAds,
    match,
    history,
    campaign,
    status,
    onFieldUpdate,
    onFieldValidate,
    refetch,
    shouldHaveExternalId = false,
  } = props;

  const location = usePreviousLocation();

  const [selectedTab, setTab] = useState(
    getDefaultTab(values.status, location)
  );
  const [formSubmitting, setFormSubmitting] = useState(isSubmitting);
  const isMonitoringTab = selectedTab === 0;
  const isCampaignTab = selectedTab === 1;
  const isIabCategoriesTab = selectedTab === 2;
  const isTargetingTab = selectedTab === 3;
  const isListAdsTab = selectedTab === 4;
  const isCampaignActivityTab = selectedTab === 5;

  const tabClasses = TabStyles();
  const classes = useStyles();

  const shouldDisplayTargetingRestrictions = !!campaign?.targetingDefinitionV2;
  const shouldHideTargeting =
    values.targetingVersion === TargetingVersion.TargetingV2 &&
    !values.targetingDefinitionV2;

  return (
    <>
      <Form className={tabClasses.form} role="form">
        {update && campaign?.order && (
          <>
            <EntityProgressBar
              statusData={getCampaignStatusData(
                values,
                (campaign.order as Order).status
              )}
              currentStatus={values.status || ''}
              dataTc="campaignStatusBar"
            />
            <EntityHeader
              entityName={values.name}
              statusValues={campaignStatusValues}
              currentStatus={values.status || ''}
              onChange={setFieldValue}
              onUpdate={onFieldUpdate as () => void}
              onValidate={onFieldValidate as () => void}
              dataTc="campaignHeader"
              error={status?.status || null}
              isStatusEditable={hasEditPermissions}
            />
          </>
        )}
        <AppBar position="static" classes={{ root: tabClasses.bar }}>
          <Tabs
            value={selectedTab}
            onChange={(e, index: number) => {
              setTab(index);
              history && handleTabHistory(history, index);
            }}
            data-tc="campaignTabs"
          >
            <Tab
              disabled={
                values.status !== CampaignStatus.Live &&
                values.status !== CampaignStatus.Completed &&
                values.status !== CampaignStatus.Paused
              }
              dataTc="campaignMonitoringTab"
              label="Performance Monitoring"
            />
            <Tab
              dataTc="campaignDetailsTab"
              label="Details"
              numberOfTabErrors={getCampaignDetailsTabErrors(
                status,
                campaignDetailsFields
              )}
            />
            <Tab dataTc="campaignIabCategoriesTab" label="IAB Categories" />
            <Tab dataTc="campaignTargetingTab" label="Targeting" />
            <Tab disabled={!update} dataTc="campaignAdsList" label="Ads" />
            <Tab
              disabled={!update}
              dataTc="campaignActivityTab"
              label="Activity"
            />
          </Tabs>
        </AppBar>
        <div
          className={classNames([
            tabClasses.formView,
            {
              [`${tabClasses.formView}--table`]:
                isListAdsTab || isCampaignActivityTab,
            },
            {
              [`${tabClasses.formView}--noPadding`]:
                isTargetingTab &&
                values.targetingVersion === TargetingVersion.TargetingV2,
            },
          ])}
        >
          {isMonitoringTab && (
            <CampaignMonitoring id={values.id} timeZone={values.timeZone} />
          )}
          {isCampaignTab && (
            <CampaignDetails
              {...props}
              isTargetingVersionToggleDisabled={update && !!allAds?.length}
              shouldHaveExternalId={shouldHaveExternalId}
              shouldDisplayTargetingRestrictions={
                shouldDisplayTargetingRestrictions
              }
            />
          )}
          {isIabCategoriesTab && (
            <IabCategoriesTree
              dataTc="campaignIabCategories"
              iabCategoryCodes={values.iabCategoryCodes}
              onChange={setFieldValue}
              attribute="iabCategoryCodes"
            />
          )}
          {isTargetingTab && (
            <TargetingVersionSelection
              {...props}
              hasTargetingVersionToggle={false}
              hasTargetingV2HelpAndTutLink={false}
            >
              {shouldHideTargeting && (
                <div className={classes.targetingMessageWrapper}>
                  Please apply targeting at the Ad level
                </div>
              )}
            </TargetingVersionSelection>
          )}
          {isListAdsTab && allAds && history && match && refetch && (
            <AdsTable
              timeZone={(campaign as Campaign).timeZone}
              values={values}
              allAds={allAds}
              hasEditPermissions={hasEditPermissions}
              match={match}
              history={history}
              refetch={refetch}
            />
          )}
          {isCampaignActivityTab && (
            <ActivityTable
              entityType={EntityType.Campaign}
              id={values.id}
              data-tc="campaignActivityTable"
            />
          )}
        </div>
        <FormButtons
          dataTc="submitCampaignButton"
          disabled={!isValid || !dirty || isSubmitting}
          onClick={handleSubmit}
          isLoading={isSubmitting}
          goBackTo={goBackTo}
          variant={FormButtonVariants.split}
          buttons={campaignButtons.map((button) => ({
            text: button.text,
            onClick: () => {
              setFormSubmitting(true);
              handleOnSubmitJourney(
                update && button.update ? '' : button.url,
                setFieldValue,
                handleSubmit
              );
            },
            isLoading: isSubmitting,
            disabled: !isValid || !dirty || isSubmitting || !hasEditPermissions,
            dataTc: button.dataTc,
          }))}
        />
      </Form>
      <LeavingPrompt when={dirty && !formSubmitting} />
    </>
  );
};

export default CampaignTabsForm;
