import BreadcrumbNav from 'components/BreadcrumbNav/BreadcrumbNav';
import ErrorDialog from 'components/ErrorDialog/ErrorDialog';
import WarningDialog from 'components/WarningDialog/WarningDialog';
import { useSessionContext } from 'context/SessionProvider/SessionProvider';
import { IAdValues } from 'features/direct/ad/components/AdTabsForm/AdFormValues';
import AdTabsForm from 'features/direct/ad/components/AdTabsForm/AdTabsForm';
import AdValidation from 'features/direct/ad/validations/ads';
import {
  allCreativeStatuses,
  CreativeStatusEnum,
} from 'features/direct/creative/components/CreativeForm/CreativeForm.values';
import { ICreativeAudioFormValues } from 'features/direct/creative/graphql/queries';
import useAllParametersInfo from 'features/targetingV2/hooks/AllParametersInfo/useAllParametersInfo';
import { sendTargetingV2DataToGTM } from 'features/targetingV2/utils/gtmData';
import { Formik, FormikHelpers as FormikActions } from 'formik';
import {
  GET_ALL_AFFECTED_ENTITIES,
  IGetAffectedEntitiesResponse,
} from 'graphql/common/queries';
import { History } from 'history';
import useError from 'hooks/Error/useError';
import { useGTMLogger } from 'hooks/GTMLogger/useGTMLogger';
import useLazyQuery from 'hooks/LazyQuery/useLazyQuery';
import usePreviousLocation from 'hooks/PreviousLocation/usePreviousLocation';
import useWarning from 'hooks/Warning/useWarning';
import { IFieldUpdate, IFieldValidate, IFormValues } from 'interfaces';
import {
  Ad,
  Advertiser,
  Campaign,
  CreateCreativeAudioMutationFn,
  CreateCreativeImageMutationFn,
  CreateCreativeRedirectMutationFn,
  CreativeType,
  EntityType,
  Order,
  TargetingVersion,
  Territory,
  UpdateAdMutation,
  UpdateAdMutationFn,
  UpdateCreativeAudioMutationFn,
  UpdateCreativeImageMutationFn,
  UpdateCreativeRedirectMutationFn,
  useCreateCreativeAudioMutation,
  useCreateCreativeImageMutation,
  useCreateCreativeRedirectMutation,
  useUpdateAdMutation,
  useUpdateCreativeAudioMutation,
  useUpdateCreativeImageMutation,
  useUpdateCreativeRedirectMutation,
} from 'interfaces/generated.types';
import React, { useState } from 'react';
import { createDirectBreadcrumbConfig } from 'utils/breadcrumbConfigFactory';
import { transformTrackingUrls } from 'utils/dataTransformation';
import dateUtils, { TimeZones } from 'utils/date';
import {
  differenceInValues,
  handleFieldUpdate,
  handleFieldValidate,
  handleFormErrors,
} from 'utils/forms';
import { handleOnJourneyRouting } from 'utils/journeys';
import { parseFormattedValue } from 'utils/numbers';
import { getTargetingDefinitionForUpdate } from 'utils/targetingDefinitions';

import formatInitialValues from './formatUpdateAdInitialValues';

interface IUpdateAdContainerProps {
  history: History;
  ad: Ad;
  hasEditPermissions: boolean;
}

export const handleOnAdComplete = async (
  response: UpdateAdMutation,
  history: History,
  campaignId: string,
  selectedJourney: string
) => {
  const { updateAd } = response;
  const { id } = updateAd as Ad;

  handleOnJourneyRouting({
    history,
    selectedJourney,
    id,
    parentId: campaignId,
    listEntity: selectedJourney === '/campaign/PARENT_ID' ? 'Ad' : '',
  });
};

export const submitForm = async ({
  advertiserId,
  updateAd,
  createImageCreative,
  createCreativeRedirect,
  createAudioCreative,
  timeZone,
  toggleErrorModal,
  initialValues,
  setSelectedJourney,
  updateAudioCreative,
  updateImageCreative,
  updateCreativeRedirect,
  territory,
  formValues,
  formikActions: { setStatus, setSubmitting },
}: {
  advertiserId: string;
  updateAd: UpdateAdMutationFn;
  createCreativeRedirect: CreateCreativeRedirectMutationFn;
  createImageCreative: CreateCreativeImageMutationFn;
  createAudioCreative: CreateCreativeAudioMutationFn;
  updateCreativeRedirect: UpdateCreativeRedirectMutationFn;
  updateImageCreative: UpdateCreativeImageMutationFn;
  updateAudioCreative: UpdateCreativeAudioMutationFn;
  timeZone: TimeZones;
  toggleErrorModal: () => void;
  initialValues: IAdValues;
  setSelectedJourney: any;
  territory: Territory;
  formValues: IAdValues;
  formikActions: FormikActions<IAdValues>;
}) => {
  let audioCreativeId: string | null = null;
  let imageCreativeIds: string[] = [];
  let creativeRedirectId: string | null = null;
  const hasRedirectUrl = !!(
    formValues.creativeRedirect &&
    (formValues.creativeRedirect.url ||
      formValues.creativeRedirect.consentVendorId)
  );

  setSelectedJourney(formValues.journey);

  if (formValues.imageCreatives.length && !hasRedirectUrl) {
    const imagesToUpdate = formValues.imageCreatives.filter(
      (image) =>
        image.id &&
        allCreativeStatuses.includes(image.status as CreativeStatusEnum)
    );
    const imagesFromLibrary = formValues.imageCreatives.filter(
      (image) =>
        image.id &&
        !allCreativeStatuses.includes(image.status as CreativeStatusEnum)
    );
    const imagesToCreate = formValues.imageCreatives.filter(
      (imageCreative) => !imageCreative.id
    );

    const updateImagesToUpdate = imagesToUpdate.map((imageCreative) => {
      const imageDetails = {
        trackingUrls: transformTrackingUrls(imageCreative.trackingUrls),
        landingPageUrl: imageCreative.landingPageUrl
          ? imageCreative.landingPageUrl
          : null,
      };
      return updateImageCreative({
        variables: {
          id: imageCreative.id,
          type: CreativeType.Image,
          ...imageDetails,
        },
      });
    });

    const uploadImagesToCreate = imagesToCreate.map((imageCreative: any) => {
      const imageDetails = {
        mimeType: imageCreative.file
          ? imageCreative.file.type
          : imageCreative.type,
        name: imageCreative.name,
        fileName: imageCreative.fileName,
        trackingUrls: transformTrackingUrls(imageCreative.trackingUrls),
        size: imageCreative.dimensions,
        url: imageCreative.url,
        landingPageUrl: imageCreative.landingPageUrl
          ? imageCreative.landingPageUrl
          : null,
      };
      return createImageCreative({
        variables: {
          advertiserId,
          type: CreativeType.Image,
          ...imageDetails,
          territory,
        },
      });
    });

    const uploadImagesFromLibrary = imagesFromLibrary.map((imageCreative) => {
      const imageDetails = {
        mimeType: imageCreative.mimeType,
        name: imageCreative.name,
        fileName: imageCreative.fileName,
        uploadedDate: imageCreative.uploadedDate,
        trackingUrls: transformTrackingUrls(imageCreative.trackingUrls),
        size: imageCreative.size,
        url: imageCreative.url,
        landingPageUrl: imageCreative.landingPageUrl
          ? imageCreative.landingPageUrl
          : null,
      };
      return createImageCreative({
        variables: {
          advertiserId,
          type: CreativeType.Image,
          ...imageDetails,
          territory,
        },
      });
    });

    const uploadedImages = await Promise.all([
      ...uploadImagesToCreate,
      ...uploadImagesFromLibrary,
    ]);
    const uploadedImagesIds = uploadedImages.map(
      (response: any) => response.data.createCreative.id
    );
    const updatedImages = await Promise.all(updateImagesToUpdate);
    const updatedImagesIds = updatedImages.map(
      (response: any) => response.data.updateCreative.id
    );

    imageCreativeIds = [...uploadedImagesIds, ...updatedImagesIds];
  }

  if (formValues.audioCreatives.length && !hasRedirectUrl) {
    const [audioCreative] =
      formValues.audioCreatives as ICreativeAudioFormValues[];
    if (
      audioCreative.id &&
      allCreativeStatuses.includes(audioCreative.status)
    ) {
      const updateCreativeResponse: any = await updateAudioCreative({
        variables: {
          id: audioCreative.id,
          type: CreativeType.Audio,
          trackingUrls: transformTrackingUrls(audioCreative.trackingUrls),
        },
      });

      audioCreativeId = updateCreativeResponse.data.updateCreative.id;
    } else {
      const commonAudioDetails = {
        name: audioCreative.name,
        uploadedDate: audioCreative.uploadedDate,
        fileName: audioCreative.fileName,
        duration: audioCreative.duration,
        url: audioCreative.url,
        trackingUrls: transformTrackingUrls(audioCreative.trackingUrls),
      };
      const audioDetails =
        audioCreative.status === CreativeStatusEnum.HasCreative
          ? {
              ...commonAudioDetails,
              mimeType: audioCreative.mimeType,
            }
          : {
              ...commonAudioDetails,
              mimeType: audioCreative.file
                ? audioCreative.file.type
                : audioCreative.type,
            };
      const response: any = await createAudioCreative({
        variables: {
          ...audioDetails,
          advertiserId,
          type: CreativeType.Audio,
          territory,
        },
      });

      audioCreativeId = response.data.createCreative.id;
    }
  }

  if (hasRedirectUrl) {
    const { id, url, consentVendorId, trackingUrls } =
      formValues.creativeRedirect;

    if (id) {
      const response: any = await updateCreativeRedirect({
        variables: {
          id,
          type: CreativeType.Redirect,
          url: url || null,
          consentVendorId: consentVendorId
            ? parseFormattedValue(consentVendorId)
            : null,
          trackingUrls: trackingUrls ? transformTrackingUrls(trackingUrls) : [],
        },
      });

      creativeRedirectId = response.data.updateCreative.id;
    } else {
      const response: any = await createCreativeRedirect({
        variables: {
          advertiserId,
          type: CreativeType.Redirect,
          url: url || null,
          territory,
          consentVendorId: consentVendorId
            ? parseFormattedValue(consentVendorId)
            : null,
          trackingUrls: trackingUrls ? transformTrackingUrls(trackingUrls) : [],
        },
      });

      creativeRedirectId = response.data.createCreative.id;
    }
  }

  const filteredValues: any = {
    ...differenceInValues(
      {
        ...formValues,
        timeZone,
        creativeRedirectId,
        sequence: formValues.sequence.map((seq) => seq.id),
        creativeAudioId: audioCreativeId,
        creativeImageIds: imageCreativeIds,
        channels: null,
        journey: null,
        campaignName: null,
        campaignCpm: null,
        audioCreatives: null,
        imageCreatives: null,
        creativeRedirect: null,
        targetingGroups: null,
        targetingMasterTemplates: null,
        targetingGeneralGroup: null,
        ...getTargetingDefinitionForUpdate(formValues),
      },
      {
        ...initialValues,
        timeZone,
        creativeRedirectId: initialValues.creativeRedirect.id,
        creativeAudioId: initialValues.audioCreatives.length
          ? initialValues.audioCreatives[0].id
          : null,
        creativeImageIds: initialValues.imageCreatives.map(
          (creative) => creative.id
        ),
        channels: null,
        journey: null,
        campaignName: null,
        campaignCpm: null,
        audioCreatives: null,
        imageCreatives: null,
        creativeRedirect: null,
        targetingGroups: null,
        targetingMasterTemplates: null,
        targetingGeneralGroup: null,
        ...getTargetingDefinitionForUpdate(initialValues),
      }
    ),
  } as IFormValues;

  delete filteredValues.isEndDateInheritable;
  delete filteredValues.endDateIsInherited;
  delete filteredValues.startDateIsInherited;
  delete filteredValues.targetingVersion;

  updateAd({
    variables: {
      ...filteredValues,
      id: formValues.id,
    },
  }).catch((error) =>
    handleFormErrors({
      error,
      toggleErrorModal,
      setStatus,
      setSubmitting,
    })
  );
};

const UpdateAdContainer = ({
  history,
  ad,
  hasEditPermissions,
}: IUpdateAdContainerProps) => {
  const {
    state: {
      user: { activeTerritory },
    },
  } = useSessionContext();

  const { id: advertiserId } = ((ad.campaign as Campaign).order as Order)
    .advertiser as Advertiser;
  const { hasError, toggleErrorModal, errorMessages } = useError([
    "Something went wrong and we couldn't update the Ad.",
    'Please try again later.',
  ]);
  const [selectedJourney, setSelectedJourney] = useState('');
  const {
    hasWarning,
    handleWarningContinue,
    toggleWarningModal,
    setWarningModal,
  } = useWarning();

  const [updateAd] = useUpdateAdMutation({
    onCompleted(data) {
      handleOnAdComplete(
        data,
        history,
        (ad.campaign as Campaign).id,
        selectedJourney
      );
    },
  });
  const validateAd = useLazyQuery<IGetAffectedEntitiesResponse>(
    GET_ALL_AFFECTED_ENTITIES
  );

  const [updateAudioCreative] = useUpdateCreativeAudioMutation();
  const [updateImageCreative] = useUpdateCreativeImageMutation();
  const [updateCreativeRedirect] = useUpdateCreativeRedirectMutation();

  const [createImageCreative] = useCreateCreativeImageMutation();
  const [createAudioCreative] = useCreateCreativeAudioMutation();
  const [createCreativeRedirect] = useCreateCreativeRedirectMutation();

  const initialValues = formatInitialValues(ad, activeTerritory);

  const location = usePreviousLocation();

  const breadcrumbConfig = createDirectBreadcrumbConfig(ad);

  const { allParametersInfo, loading: allParametersInfoLoading } =
    useAllParametersInfo();

  const gtmLogger = useGTMLogger();

  const shouldHideTargeting = !!(
    ad.campaign?.targetingVersion === TargetingVersion.TargetingV2 &&
    ad.campaign?.targetingDefinitionV2
  );

  return (
    <>
      <BreadcrumbNav config={breadcrumbConfig} />
      <Formik<IAdValues>
        data-tc="adForm"
        initialValues={initialValues}
        validationSchema={AdValidation(
          (ad.campaign as Campaign).startDate
            ? dateUtils.getDateInSpecificTimezone(
                (ad.campaign as Campaign).startDate,
                (ad.campaign as Campaign).timeZone
              )
            : null,
          (ad.campaign as Campaign).endDate ||
            ((ad.campaign as Campaign).order as Order).endDate
            ? dateUtils.getDateInSpecificTimezone(
                (ad.campaign as Campaign).endDate ||
                  ((ad.campaign as Campaign).order as Order).endDate ||
                  '',
                (ad.campaign as Campaign).endDate
                  ? (ad.campaign as Campaign).timeZone
                  : ((ad.campaign as Campaign).order as Order).timeZone
              )
            : null,
          (ad.campaign as Campaign).timeZone,
          (ad.campaign as Campaign).dailyCap
            ? (ad.campaign as Campaign).dailyCap
            : null,
          activeTerritory!,
          ad.status
        )}
        onSubmit={(formValues, formikActions) => {
          if (formValues.targetingVersion === TargetingVersion.TargetingV2) {
            sendTargetingV2DataToGTM(
              formValues.targetingDefinitionV2,
              allParametersInfo,
              gtmLogger,
              formValues.territory
            );
          }

          submitForm({
            advertiserId,
            createAudioCreative,
            createCreativeRedirect,
            createImageCreative,
            initialValues,
            setSelectedJourney,
            timeZone: (ad.campaign as Campaign).timeZone,
            toggleErrorModal,
            updateAd,
            updateAudioCreative,
            updateImageCreative,
            updateCreativeRedirect,
            territory: activeTerritory!,
            formValues,
            formikActions,
          });
        }}
      >
        {(formikProps) => (
          <AdTabsForm
            {...formikProps}
            advertiserId={advertiserId}
            update
            history={history}
            goBackTo={{
              pathname:
                location.state?.from ||
                `/campaign/${(ad.campaign as Campaign).id}`,
              state: location.state?.parent || {},
            }}
            campaignTags={(ad.campaign as Campaign).tags}
            hasEditPermissions={hasEditPermissions}
            campaignStatus={(ad.campaign as Campaign).status}
            campaignTimeZone={(ad.campaign as Campaign).timeZone}
            shouldHideTargeting={shouldHideTargeting}
            onFieldValidate={({
              entity,
              handleContinue,
              handleCancel,
            }: IFieldValidate) =>
              handleFieldValidate({
                validate: validateAd,
                entity: {
                  ...entity,
                  id: formikProps.values.id,
                  type: EntityType.Ad,
                },
                toggleErrorModal,
                setWarningModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
                handleContinue,
                handleCancel,
              })
            }
            onFieldUpdate={({ entity }: IFieldUpdate) =>
              handleFieldUpdate({
                entity: { ...entity, id: formikProps.values.id },
                update: updateAd,
                toggleErrorModal,
                setSubmitting: formikProps.setSubmitting,
                setStatus: formikProps.setStatus,
              })
            }
            loading={allParametersInfoLoading}
          />
        )}
      </Formik>
      <ErrorDialog
        content={{
          title: 'Error',
          closeButton: 'Close',
        }}
        isOpen={hasError}
        handleClose={toggleErrorModal}
        dataTc="updateAd"
        errorMessages={errorMessages}
      />
      <WarningDialog
        handleContinue={handleWarningContinue}
        handleClose={toggleWarningModal}
        isOpen={hasWarning}
        dataTc="updateAdWarningDialog"
      />
    </>
  );
};

export default UpdateAdContainer;
